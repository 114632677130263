;
window.AdjustIframeHeightOnLoad = (iframeId) => {
    AVH_iframesMap[iframeId] = {
        [0]: document.getElementById(iframeId),
        [1]: AVH_initialIframeHeight,
    };
};
const AVH_castusOrigin = 'https://cloud.castus.tv', AVH_initialIframeHeight = '0px', AVH_heightPaddingInPx = 16, AVH_iframesMap = {}, AVH_adjustIframeHeightIfChanged = (event) => {
    if (event.origin !== AVH_castusOrigin) {
        return;
    }
    const eventData = event.data, id = eventData.embedID, iframeData = AVH_iframesMap[id];
    if (!iframeData) {
        return;
    }
    const existingHeight = iframeData[1], newHeight = eventData.height;
    if (newHeight === existingHeight) {
        return;
    }
    const $targetIframe = iframeData[0];
    iframeData[1] = newHeight;
    $targetIframe.style.height = AVH_padHeight(newHeight);
    ;
}, AVH_padHeight = (initialHeightStr) => {
    const initialHeightWithoutPx = initialHeightStr.slice(0, -2), initialHeightNum = Number(initialHeightWithoutPx), paddedHeightNum = initialHeightNum + AVH_heightPaddingInPx;
    return `${paddedHeightNum}px`;
};
window.addEventListener('message', AVH_adjustIframeHeightIfChanged);
export {};
